import Footer from "./Footer";
import Navbar from "./Navbar";

export default function Contact() {

    return <>      
                
                <Navbar/>      
                <Footer/>
                      
      </>
}