
import Navbar from "./Navbar";
export default function Whychoose() {

           return <>


<body style={{backgroundColor:'white'}}>

  <Navbar/>                   
<div className="choose ">
   <div className="container">
      <div className="row">
         <div className="col-md-12">
            <div className="titlepage">
               <h2>Why Choose Us</h2>
               <span>It is a long established fact that a reader will be distracted by the readable content of a page when<br/> looking at its layout. The point of using </span>
            </div>
         </div>
      </div>
      <div className="row">
         <div className="col-md-12">
            <div className="choose_box">
               <span>01</span>
               <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making </p>
            </div>
         </div>
         <div className="col-md-12">
            <div className="choose_box">
               <span>02</span>
               <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making </p>
            </div>
         </div>
         <div className="col-md-12">
            <div className="choose_box">
               <span>03</span>
               <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making </p>
            </div>
         </div>
      </div>
   </div>
</div><br />


<footer> 
<div>
<section className="change red_bg" style={{padding:'20px 0',textAlign:'center',color:'#ffffff'}}>
  <div className="container">
    <div className="row align-items-center">
      <div className="col-xl-9 col-lg-9 col-12">
        <div className="change_content">
          <h2 style={{fontSize:"24px",fontWeight:'bold'}}>Drive Into the Future, Join Rento Today!</h2>
          <h5 style={{color: 'black' ,marginTop:'10px',fontSize:'16.39px'}}>
            Experience hassle-free vehicle rentals with Rento. Whether for business, travel, or adventure, 
            we provide you with the convenience and reliability you deserve. Start your journey with us and 
            make every mile memorable.
          </h5>
        </div>
      </div>
    </div>
  </div>
</section>

    

      <div className="copyright">
         <div className="container">
            <div className="row">
               <div className="col-md-12 ">
                  <p>© 2019 All Rights Reserved. Design by Rento</p>
               </div>
            </div>
         </div>
      </div> 

       </div>
   </footer>  

        
        </body>
        </>
}